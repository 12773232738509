import React, { useState, useEffect } from 'react';

const AddHomeSlider = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState('');
  const [sliders, setSliders] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [previewUrl, setPreviewUrl] = useState(null);

  const baseURL = 'http://88.222.242.92:5000';
  const API_URL = `${baseURL}/homeslider`;

  const getImageUrl = (imagePath) => {
    if (!imagePath) return '';
    return `${baseURL}/uploads/${imagePath}`;
  };

  // Fetch existing sliders
  useEffect(() => {
    fetchSliders();
  }, [refreshKey]);

  const fetchSliders = async () => {
    try {
      const response = await fetch(API_URL);
      if (!response.ok) {
        throw new Error('Failed to fetch sliders');
      }
      const data = await response.json();
      setSliders(data.data || []);
    } catch (err) {
      console.error('Error fetching sliders:', err);
      setError('Failed to fetch sliders');
      setTimeout(() => setError(null), 3000);
    }
  };

  // Handle image file selection
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
      setSelectedImage(file);
      setPreviewUrl(URL.createObjectURL(file));
      setError(null);
      setSuccess('');
    }
  };

  // Handle delete slider
  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this slider?')) {
      try {
        const response = await fetch(`${API_URL}/${id}`, {
          method: 'DELETE',
        });
        
        if (!response.ok) {
          throw new Error('Failed to delete slider');
        }
        
        setSuccess('Slider deleted successfully!');
        setRefreshKey(old => old + 1);
        setTimeout(() => setSuccess(''), 3000);
      } catch (err) {
        setError('Failed to delete slider');
        setTimeout(() => setError(null), 3000);
        console.error('Delete error:', err);
      }
    }
  };

  // Reset form
  const resetForm = () => {
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
    }
    setSelectedImage(null);
    setPreviewUrl(null);
    setDescription('');
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) fileInput.value = '';
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!selectedImage) {
      setError('Please select an image to upload.');
      setTimeout(() => setError(null), 3000);
      return;
    }

    try {
      setLoading(true);
      setError(null);
      setSuccess('');

      const formData = new FormData();
      formData.append('SlideImage', selectedImage);
      formData.append('Description', description);

      const response = await fetch(API_URL, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to upload image');
      }

      const result = await response.json();
      
      if (result) {
        // Reset form
        resetForm();
        
        // Refresh the sliders list
        setRefreshKey(old => old + 1);

        // Show success message
        setSuccess('Image uploaded successfully!');
        setTimeout(() => setSuccess(''), 3000);
      }

    } catch (err) {
      setError(err.message || 'An error occurred while uploading the image.');
      setTimeout(() => setError(null), 3000);
      console.error('Upload error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-6">
      {/* Add Form Section */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-2xl font-semibold mb-4">Add HomeSlider Image</h2>

        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-700 rounded">
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 p-3 bg-green-100 text-green-700 rounded">
            {success}
          </div>
        )}

        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          {/* Image File Picker */}
          <label className="block">
            <span className="text-gray-700">Select Image:</span>
            <input 
              type="file" 
              accept="image/*"
              onChange={handleImageChange} 
              className="mt-2 block w-full text-sm text-gray-500
                         file:mr-4 file:py-2 file:px-4
                         file:rounded-full file:border-0
                         file:text-sm file:font-semibold
                         file:bg-blue-500 file:text-white
                         hover:file:bg-blue-600"
            />
          </label>

          {/* Description Input */}
          <label className="block">
            <span className="text-gray-700">Description:</span>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                       focus:border-blue-300 focus:ring focus:ring-blue-200
                       focus:ring-opacity-50"
              placeholder="Enter image description"
            />
          </label>

          {/* Submit Button */}
          <button 
            type="submit" 
            disabled={loading}
            className={`mt-4 px-4 py-2 bg-blue-500 text-white rounded 
                       hover:bg-blue-600 transition
                       ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {loading ? 'Uploading...' : 'Add Image'}
          </button>
        </form>

        {/* Preview Selected Image */}
        {previewUrl && (
          <div className="mt-4">
            <h3 className="text-gray-700">Selected Image Preview:</h3>
            <img 
              src={previewUrl}
              alt="Selected preview" 
              className="mt-2 w-full max-w-xs h-auto rounded shadow-lg"
            />
          </div>
        )}
      </div>

      {/* Existing Sliders Section */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-semibold mb-4">Existing Sliders</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {sliders.map((slider) => (
            <div key={slider._id} className="border rounded-lg p-4 shadow-sm">
              <img
                src={getImageUrl(slider.SlideImage)}
                alt={slider.Description}
                className="w-full h-48 object-cover rounded-lg mb-3"
                crossOrigin="anonymous"
              />
              <p className="text-gray-700 mb-3">{slider.Description}</p>
              <button
                onClick={() => handleDelete(slider._id)}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition"
              >
                Delete
              </button>
            </div>
          ))}
        </div>
        {sliders.length === 0 && (
          <p className="text-gray-500 text-center">No sliders found</p>
        )}
      </div>
    </div>
  );
};

export default AddHomeSlider;