import React, { useState, useEffect } from 'react';

const ContactUsDetails = () => {
  const [contactDetails, setContactDetails] = useState([]);

  useEffect(() => {
    fetchContactUsDetails();
  }, []);

  const fetchContactUsDetails = async () => {
    try {
      const response = await fetch('http://88.222.242.92:5000/contacus');
      const data = await response.json();
      setContactDetails(data.data);
    } catch (error) {
      console.error('Error fetching contact us details:', error);
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-8 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-gray-800 text-center mb-6">Contact Us Details</h2>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
          <thead className="bg-gray-900 text-white">
            <tr>
              <th className="py-3 px-6 text-left text-sm font-medium text-gray-200">Name</th>
              <th className="py-3 px-6 text-left text-sm font-medium text-gray-200">Email</th>
              <th className="py-3 px-6 text-left text-sm font-medium text-gray-200">Mobile Number</th>
              <th className="py-3 px-6 text-left text-sm font-medium text-gray-200">Message</th>
            </tr>
          </thead>
          <tbody>
            {contactDetails.map((contact, index) => (
              <tr
                key={contact._id}
                className="border-t border-b hover:bg-gray-50 transition duration-200"
              >
                <td className="py-4 px-6 text-sm text-gray-700">{contact.Name}</td>
                <td className="py-4 px-6 text-sm text-gray-700">{contact.Email}</td>
                <td className="py-4 px-6 text-sm text-gray-700">{contact.MobileNumber}</td>
                <td className="py-4 px-6 text-sm text-gray-700">{contact.Message}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContactUsDetails;