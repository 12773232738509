import React, { useState } from 'react';
import axios from 'axios';

const AddMagazine = () => {
  const [magazineData, setMagazineData] = useState({
    Title: '',
    Description: '',
    MagazineImages: '',
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setMagazineData({
      ...magazineData,
      [name]: type === 'file' ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Create a new FormData object to send both the form data and the image
      const formData = new FormData();
      formData.append('Title', magazineData.Title);
      formData.append('Description', magazineData.Description);
      formData.append('MagazineImages', magazineData.MagazineImages);

      // Send a POST request to the API endpoint
      await axios.post('http://88.222.242.92:5000/magazine', formData);

      // Reset the form after successful submission
      setMagazineData({
        Title: '',
        Description: '',
        MagazineImages: null,
      });

      console.log('Magazine data and image uploaded successfully');
    } catch (error) {
      console.error('Error uploading magazine data and image:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-xl font-semibold mb-4">Add Magazine</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Title</label>
          <input
            type="text"
            name="Title"
            value={magazineData.Title}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Description</label>
          <textarea
            name="Description"
            value={magazineData.Description}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            rows="4"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Magazine Image</label>
          <input
            type="file"
            name="MagazineImages"
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="flex justify-end space-x-2">
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddMagazine;