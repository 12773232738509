import React, { useState, useEffect } from 'react';
import { Loader2 } from 'lucide-react';

const API_URL = 'http://88.222.242.92:5000/church-gallery';
const IMAGE_BASE_URL = 'http://88.222.242.92:5000/uploads/';

const ManageGallery = () => {
  const [galleryImages, setGalleryImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageData, setImageData] = useState({ file: null });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  // Fetch gallery images
  const fetchGalleryImages = async () => {
    try {
      const response = await fetch(API_URL);
      const result = await response.json();
      if (result.data) {
        setGalleryImages(result.data);
      }
    } catch (error) {
      console.error('Error fetching gallery images:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGalleryImages();
  }, []);

  // Handle edit functionality
  const handleEdit = (image) => {
    setSelectedImage(image);
    setImageData({ file: null });
    setIsModalOpen(true);
  };

  // Handle delete functionality
  const handleDelete = async (id) => {
    const userConfirmed = window.confirm('Are you sure you want to delete this image?');
    if (!userConfirmed) return;

    try {
      const response = await fetch(`${API_URL}/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setGalleryImages(galleryImages.filter(image => image._id !== id));
      } else {
        throw new Error('Failed to delete image');
      }
    } catch (error) {
      console.error('Error deleting image:', error);
      alert('Failed to delete image. Please try again.');
    }
  };

  // Handle image file change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageData({ file });
  };

  // Handle update functionality
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!imageData.file) {
      alert('Please select an image to update.');
      return;
    }

    const formData = new FormData();
    formData.append('GalleryImages', imageData.file);

    try {
      const response = await fetch(`${API_URL}/${selectedImage._id}`, {
        method: 'PUT',
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        setGalleryImages(
          galleryImages.map(image =>
            image._id === selectedImage._id ? result.data : image
          )
        );
        setIsModalOpen(false);
        setSelectedImage(null);
        fetchGalleryImages();
        setImageData({ file: null });
      } else {
        throw new Error('Failed to update image');
      }
    } catch (error) {
      console.error('Error updating image:', error);
      alert('Failed to update image. Please try again.');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h1 className="text-2xl font-bold mb-6">Church Gallery Manager</h1>
        
        {/* Image Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {galleryImages.map((image) => (
            <div key={image._id} className="bg-white rounded-lg shadow-md overflow-hidden">
              <img
                src={`${IMAGE_BASE_URL}${image.GalleryImages}`}
                alt="Gallery"
                className="w-full h-48 object-cover"
                crossOrigin="anonymous"
              />
              <div className="p-4">
                <p className="text-sm text-gray-500 mb-4">
                  Created: {new Date(image.createdAt).toLocaleDateString()}
                </p>
                <div className="flex space-x-2">
                  <button
                    onClick={() => handleEdit(image)}
                    className="flex-1 py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(image._id)}
                    className="flex-1 py-2 px-4 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Edit Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full">
              <h2 className="text-2xl font-bold mb-4">Update Image</h2>
              <form onSubmit={handleUpdate} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Select New Image
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="w-full border rounded-md p-2"
                  />
                </div>

                {imageData.file && (
                  <div className="mt-4">
                    <img
                      src={URL.createObjectURL(imageData.file)}
                      alt="Preview"
                      className="w-32 h-32 object-cover rounded-md"
                  crossOrigin="anonymous"
                    />
                  </div>
                )}

                <div className="flex space-x-2">
                  <button
                    type="submit"
                    className="flex-1 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setIsModalOpen(false);
                      setSelectedImage(null);
                      setImageData({ file: null });
                    }}
                    className="flex-1 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition-colors"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageGallery;