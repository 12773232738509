import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ManageMagazine = () => {
  const [magazines, setMagazines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editData, setEditData] = useState({
    _id: null,
    Title: '',
    Description: '',
    MagazineImages: null,
  });

  // Base URL for API calls
  const baseURL = 'http://88.222.242.92:5000';

  // Function to get image URL with credentials
  const getImageUrl = (imagePath) => {
    return new URL(`/uploads/${imagePath}`, baseURL).href;
  };

  // Fetch magazines
  const fetchMagazines = async () => {
    try {
      const response = await axios.get(`${baseURL}/magazine`);
      setMagazines(response.data.data);
    } catch (err) {
      setError(err.response?.data?.message || err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMagazines();
  }, []);

  const handleEdit = (magazine) => {
    setEditData({
      _id: magazine._id,
      Title: magazine.Title,
      Description: magazine.Description,
      MagazineImages: null
    });
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this magazine?')) {
      try {
        await axios.delete(`${baseURL}/magazine/${id}`);
        fetchMagazines();
      } catch (err) {
        setError(err.response?.data?.message || err.message);
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('Title', editData.Title);
      formData.append('Description', editData.Description);
      if (editData.MagazineImages) {
        formData.append('MagazineImages', editData.MagazineImages);
      }

      await axios.put(
        `${baseURL}/magazine/${editData._id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      fetchMagazines();
      setEditData({
        _id: null,
        Title: '',
        Description: '',
        MagazineImages: null,
      });
    } catch (err) {
      setError(err.response?.data?.message || err.message);
    }
  };

  if (loading) return <div className="text-center p-4">Loading...</div>;
  if (error) return <div className="text-red-500 text-center p-4">{error}</div>;

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-6">Manage Magazines</h1>

      <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
        <thead className="bg-gray-800 text-white">
          <tr>
            <th className="px-4 py-2">Image</th>
            <th className="px-4 py-2">Title</th>
            <th className="px-4 py-2">Description</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {magazines.map((magazine) => (
            <tr key={magazine._id} className="border-b hover:bg-gray-100">
              <td className="px-4 py-2">
                <img
                  src={getImageUrl(magazine.MagazineImages)}
                  alt={magazine.Title}
                  className="w-16 h-16 object-cover"
                  crossOrigin="anonymous"
                />
              </td>
              <td className="px-4 py-2">{magazine.Title}</td>
              <td className="px-4 py-2">{magazine.Description}</td>
              <td className="px-4 py-2">
                <button
                  onClick={() => handleEdit(magazine)}
                  className="bg-blue-500 text-white py-1 px-3 rounded-md mr-2 hover:bg-blue-600"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(magazine._id)}
                  className="bg-red-500 text-white py-1 px-3 rounded-md hover:bg-red-600"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editData._id && (
        <div className="mt-8">
          <h2 className="text-xl font-semibold">Edit Magazine</h2>
          <form
            onSubmit={handleUpdate}
            className="bg-white p-6 rounded-lg shadow-md mt-4"
          >
            <div className="mb-4">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
              <input
                type="text"
                id="title"
                value={editData.Title}
                onChange={(e) => setEditData({ ...editData, Title: e.target.value })}
                className="mt-2 block w-full px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
              <textarea
                id="description"
                value={editData.Description}
                onChange={(e) => setEditData({ ...editData, Description: e.target.value })}
                className="mt-2 block w-full px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                rows="4"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="image" className="block text-sm font-medium text-gray-700">Image</label>
              <input
                type="file"
                id="image"
                onChange={(e) => setEditData({ ...editData, MagazineImages: e.target.files[0] })}
                accept="image/*"
                className="mt-2 block w-full text-sm text-gray-500 file:py-2 file:px-4 file:border file:rounded-md file:bg-gray-100 file:text-gray-700 file:cursor-pointer"
              />
            </div>

            <div className="flex gap-4">
              <button
                type="submit"
                className="flex-1 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
              >
                Update Magazine
              </button>
              <button
                type="button"
                onClick={() => setEditData({ _id: null, Title: '', Description: '', MagazineImages: null })}
                className="flex-1 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ManageMagazine;