import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ManageStories = () => {
  const [stories, setStories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStory, setSelectedStory] = useState(null);
  const [updatedStory, setUpdatedStory] = useState({
    Name: '',
    Email: '',
    MobileNumber: '',
    Testimony: '',
  });

  // Fetch stories from the API
  useEffect(() => {
    const fetchStories = async () => {
      try {
        const response = await axios.get('http://88.222.242.92:5000/share-your-story');
        setStories(response.data.data);
      } catch (error) {
        console.error('Error fetching stories:', error);
      }
    };
    fetchStories();
  }, []);

  // Handle modal open
  const handleEdit = (story) => {
    setSelectedStory(story);
    setUpdatedStory({
      Name: story.Name,
      Email: story.Email,
      MobileNumber: story.MobileNumber,
      Testimony: story.Testimony,
    });
    setIsModalOpen(true);
  };

  // Handle modal close
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedStory(null);
  };

  // Handle changes in the modal fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedStory({
      ...updatedStory,
      [name]: value,
    });
  };

  // Handle updating the story
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`http://88.222.242.92:5000/share-your-story/${selectedStory._id}`, updatedStory);
      const updatedStories = stories.map((story) =>
        story._id === selectedStory._id ? { ...story, ...updatedStory } : story
      );
      setStories(updatedStories);
      handleCloseModal();
    } catch (error) {
      console.error('Error updating story:', error);
    }
  };

  // Handle delete
  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://88.222.242.92:5000/share-your-story/${id}`);
      setStories(stories.filter((story) => story._id !== id));
    } catch (error) {
      console.error('Error deleting story:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-xl font-semibold mb-4">Manage Story Requests</h2>
      <table className="min-w-full table-auto">
        <thead>
          <tr>
            <th className="px-4 py-2 border">Name</th>
            <th className="px-4 py-2 border">Email</th>
            <th className="px-4 py-2 border">Mobile Number</th>
            <th className="px-4 py-2 border">Testimony</th>
            <th className="px-4 py-2 border">Actions</th>
          </tr>
        </thead>
        <tbody>
          {stories.map((story) => (
            <tr key={story._id}>
              <td className="px-4 py-2 border">{story.Name}</td>
              <td className="px-4 py-2 border">{story.Email}</td>
              <td className="px-4 py-2 border">{story.MobileNumber}</td>
              <td className="px-4 py-2 border">{story.Testimony}</td>
              <td className="px-4 py-2 border">
                <button
                  onClick={() => handleEdit(story)}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(story._id)}
                  className="bg-red-500 text-white px-4 py-2 rounded-md ml-2"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for Edit */}
      {isModalOpen && selectedStory && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg w-96">
            <h3 className="text-lg font-semibold mb-4">Edit Story</h3>
            <form onSubmit={handleUpdate}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Name</label>
                <input
                  type="text"
                  name="Name"
                  value={updatedStory.Name}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  name="Email"
                  value={updatedStory.Email}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Mobile Number</label>
                <input
                  type="text"
                  name="MobileNumber"
                  value={updatedStory.MobileNumber}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Testimony</label>
                <textarea
                  name="Testimony"
                  value={updatedStory.Testimony}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  rows="4"
                />
              </div>

              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={handleCloseModal}
                  className="bg-gray-500 text-white px-4 py-2 rounded-md"
                >
                  Cancel
                </button>
                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageStories;